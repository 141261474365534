import { useEffect, useState } from "react"
import styled from "styled-components"

import { ShortcodeDropdown } from "src/components/Homes/HomeDetails/Shortcodes/ShortcodeDropdown"
import { HREF_MINUT_HELP_SHORTCODES } from "src/constants/hrefs"
import { IGuestCommunicationError } from "src/data/guestCommunication/types/guestCommunicationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePatchHome } from "src/data/organizations/queries/homeQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { ConfirmDialog } from "src/ui/Dialog/ConfirmDialog"
import { MDialog } from "src/ui/Dialog/MDialog"
import { InfoBox } from "src/ui/InfoBox/InfoBox"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { TextEditor } from "src/ui/TextEditor/TextEditor"
import { stringSplice } from "src/utils/genericUtil"

export function ShortcodeDialog({
  open,
  onClose,
  homeId,
  initialData,
}: {
  open: boolean
  onClose: () => void
  homeId: string
  initialData: { message?: string; name?: string } | null
}) {
  const { t } = useTranslate()
  const { org } = useOrganization()

  const dialogTitle = initialData?.name || ""
  const formId = "short-code-form"
  const patchHome = usePatchHome<IGuestCommunicationError>()

  const [message, setMessage] = useState<string>("")
  const [caretPos, setCaretPos] = useState(0)
  const [initialMessage, setInitialMessage] = useState<string>("")
  useEffect(() => {
    if (initialData?.message) {
      setMessage(initialData.message)
      setInitialMessage(initialData.message)
      setCaretPos(initialData.message.length)
    }
  }, [initialData])

  function handleSaveCheckinMessage(msg: string) {
    patchHome.mutate(
      {
        orgId: org.id,
        homeId,
        data: {
          guest_communication: { checkin_message: msg },
        },
      },
      {
        onSuccess() {
          handleClose()
        },
      }
    )
  }

  const [confirmDiscardChanges, setConfirmDiscardChanges] = useState(false)

  function addShortcode(shortcode: string) {
    setMessage(stringSplice(message, caretPos, `{{${shortcode}}}`))
  }

  function handleSubmit() {
    handleSaveCheckinMessage(message)
  }

  function handleClose() {
    patchHome.reset()
    onClose()
  }

  function confirmClose() {
    if (initialMessage !== message) {
      setConfirmDiscardChanges(true)
    } else {
      handleClose()
    }
  }

  return (
    <MDialog
      formId={formId}
      title={dialogTitle}
      confirmLabel={t(langKeys.save)}
      open={open}
      onClose={confirmClose}
      loading={patchHome.isLoading}
      error={patchHome.isError && t(langKeys.failed_to_save)}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
        }}
        id={formId}
      >
        <Grid>
          <div>
            <MText variant="subtitle" marginBottom={spacing.S}>
              {t(langKeys.message)}
            </MText>
            <TextEditor
              toolbar={
                <ShortcodeDropdown homeId={homeId} onSelect={addShortcode} />
              }
              minRows={8}
              maxRows={8}
              value={message}
              onCaretMoved={setCaretPos}
              onChange={(e) => {
                setMessage(e.target.value)
              }}
            />
          </div>

          <ShortcodeError error={patchHome.error?.response?.data} />

          <div>
            <InfoBox title={t(langKeys.shortcodes_tip_title)}>
              <div style={{ marginBottom: spacing.M }}>
                {t(langKeys.shortcodes_tip_body)}
              </div>

              <ExternalLink href={HREF_MINUT_HELP_SHORTCODES}>
                {t(langKeys.learn_more)}
              </ExternalLink>
            </InfoBox>
          </div>
        </Grid>
        <ConfirmDialog
          title={t(langKeys.confirm_discard_changes_title)}
          description={t(langKeys.confirm_discard_changes_body)}
          confirmLabel={t(langKeys.confirm_discard_changes_discard)}
          cancelLabel={t(langKeys.confirm_discard_changes_cancel)}
          open={confirmDiscardChanges}
          onClose={() => setConfirmDiscardChanges(false)}
          onConfirm={() => {
            setConfirmDiscardChanges(false)
            handleClose()
          }}
        />
      </form>
    </MDialog>
  )
}

function ShortcodeError({
  error,
}: {
  error: IGuestCommunicationError | undefined
}) {
  if (error?.error_key !== "unsupported_shortcode") {
    return null
  }

  return (
    <MBanner type="error" fullWidth size="medium">
      {error.message}
      <br />'{error.context.guest_communication_shortcode}'
    </MBanner>
  )
}

const Grid = styled.div`
  display: grid;
  grid-gap: ${spacing.S};
`
