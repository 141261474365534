import { useState } from "react"

import { Checkbox, FormControlLabel } from "@material-ui/core"

import { HREF_MINUT_SUBSCRIPTION_TERMS } from "src/constants/hrefs"
import { useFetchHomeDeletionRefundType } from "src/data/homes/queries/homeTokenQueries"
import { TRefundType } from "src/data/homes/types/homeTokenTypes"
import { THome } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { ConfirmDialog } from "src/ui/Dialog/ConfirmDialog"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"

export function DeleteHomeDialog({
  onClose,
  onConfirm,
  loading,
  homeId,
  homeName,
}: {
  onClose: () => void
  onConfirm: () => void
  loading: boolean
  homeId: string
  homeName: THome["name"]
}) {
  const { t, langKeys } = useTranslate()
  const { orgId } = useOrganization()
  const [confirmCheckboxChecked, setConfirmCheckbox] = useState(false)

  const fetchHomeDeletionRefundType = useFetchHomeDeletionRefundType({
    orgId,
    homeId,
  })

  const homeDeletionRefundType = fetchHomeDeletionRefundType.data?.refund

  const refundTypeRequiresConfirmation =
    homeDeletionRefundType === "pre_token_expiration_pre_renewal" ||
    homeDeletionRefundType === "pre_token_expiration_post_renewal"

  return (
    <ConfirmDialog
      title={
        homeName
          ? `${t(langKeys.delete_generic_question, { name: homeName })}`
          : `${t(langKeys.home_remove_home)}?`
      }
      open={true}
      onClose={onClose}
      loading={loading}
      confirmLabel={t(langKeys.delete)}
      onConfirm={onConfirm}
      confirmButtonProps={{
        color: "destructive",
        disabled:
          fetchHomeDeletionRefundType.isError ||
          (refundTypeRequiresConfirmation && !confirmCheckboxChecked),
        loading: fetchHomeDeletionRefundType.isLoading || loading,
      }}
    >
      <RefundTypeBody
        refundType={homeDeletionRefundType}
        loading={fetchHomeDeletionRefundType.isLoading}
        error={fetchHomeDeletionRefundType.isError}
        onConfirmClick={setConfirmCheckbox}
      />
    </ConfirmDialog>
  )
}

function RefundTypeBody({
  refundType,
  loading,
  error,
  onConfirmClick,
}: {
  refundType: TRefundType | undefined
  loading: boolean
  error: boolean
  onConfirmClick: (checked: boolean) => void
}) {
  const { t, langKeys } = useTranslate()

  if (loading) {
    return <Loading />
  }

  if (error) {
    return (
      <MBanner fullWidth type="error">
        {t(langKeys.failed_something_went_wrong)}
      </MBanner>
    )
  }

  return (
    <HomeDeletionText refundType={refundType} onConfirmClick={onConfirmClick} />
  )
}

function HomeDeletionText({
  refundType,
  onConfirmClick,
}: {
  refundType?: TRefundType
  onConfirmClick: (checked: boolean) => void
}) {
  const { t, langKeys } = useTranslate()

  if (refundType === "pre_token_expiration_post_renewal") {
    return (
      <>
        <div>
          {t(langKeys.receive_credits_for_unused_time)}{" "}
          <ExternalLink href={HREF_MINUT_SUBSCRIPTION_TERMS}>
            {t(langKeys.learn_more_subscription_terms)}
          </ExternalLink>
        </div>
        <br />
        <div>{t(langKeys.all_data_and_sensors_will_be_deleted)}</div>
        <br />
        <HomeDeletionConfirmCheckbox onChecked={onConfirmClick} />
      </>
    )
  }

  if (refundType === "pre_token_expiration_pre_renewal") {
    return (
      <>
        <div>
          {t(langKeys.wont_receive_credits_for_unused_time)}{" "}
          <ExternalLink href={HREF_MINUT_SUBSCRIPTION_TERMS}>
            {t(langKeys.learn_more_subscription_terms)}
          </ExternalLink>
        </div>
        <br />
        <div>{t(langKeys.all_data_and_sensors_will_be_deleted)}</div>
        <br />
        <HomeDeletionConfirmCheckbox onChecked={onConfirmClick} />
      </>
    )
  }

  if (
    refundType === "post_token_expiration" ||
    refundType === "within_return_token_window"
  ) {
    return <div>{t(langKeys.all_data_and_sensors_will_be_deleted)}</div>
  }

  return null
}

function HomeDeletionConfirmCheckbox({
  onChecked,
}: {
  onChecked: (checked: boolean) => void
}) {
  const { t, langKeys } = useTranslate()
  function handleChange(
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    onChecked(checked)
  }
  return (
    <FormControlLabel
      control={<Checkbox required color="primary" onChange={handleChange} />}
      label={`${t(langKeys.understand_irreversible_action)}*`}
    />
  )
}

function Loading() {
  return (
    <div>
      <MSkeleton width="30%" />
      <MSkeleton width="50%" />
      <MSkeleton width="85%" />
      <MSkeleton width="45%" />
      <MSkeleton width="100%" />
    </div>
  )
}
