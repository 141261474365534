import { useMemo, useState } from "react"
import styled from "styled-components"

import { subDays } from "date-fns"

import { GraphBox } from "src/components/Homes/HomeDetails/Overview/GraphBox"
import { useFetchOccupancyData } from "src/data/occupancy/queries/occupancyQueries"
import { semanticEmergency, semanticGood } from "src/ui/colors"
import { DateRangePicker } from "src/ui/DateRangePicker/DateRangePicker"
import { LineGraphResponsive } from "src/ui/Graphs/LineGraph/LineGraph"
import { getResolution, splitToChunks } from "src/ui/Graphs/utils"
import { spacing } from "src/ui/spacing"
import { utcToHomeTimezone } from "src/utils/l10n"

export function OccupancyGraph({
  id,
  lastUpdate,
  threshold,
  stateOK,
  timezone,
  graphBoxCSSProps,
}: {
  id: string
  lastUpdate: string | undefined
  title?: string
  threshold: number
  stateOK: boolean
  timezone?: string
  graphBoxCSSProps?: React.CSSProperties
}) {
  const now = useMemo(() => new Date(), [])
  const endAt = (lastUpdate && new Date(lastUpdate)) || now
  const startAt = subDays(endAt, 1)

  const [dateRange, setDateRange] = useState({
    startDate: startAt,
    endDate: endAt,
  })

  const handleDateRangeChange = ({
    startDate,
    endDate,
  }: {
    startDate: Date
    endDate: Date
  }) => {
    setDateRange({ startDate, endDate })
  }

  const timeDomain = [
    utcToHomeTimezone(dateRange.startDate.toISOString(), timezone),
    utcToHomeTimezone(dateRange.endDate.toISOString(), timezone),
  ]

  const fetchOccupancyData = useFetchOccupancyData({
    id: id || "",
    params: {
      null_fill: 0,
      include_min_max: true,
      start_at: dateRange.startDate.toISOString(),
      end_at: dateRange.endDate.toISOString(),
    },
    queryOptions: {
      placeholderData: { values: [] },
    },
  })
  const data = useMemo(() => {
    const values =
      fetchOccupancyData.data?.values?.map((dt) => ({
        ...dt,
        datetime: utcToHomeTimezone(dt.datetime, timezone),
      })) || []

    if (!values) {
      return []
    }
    const resolution = getResolution(dateRange) * 2
    return splitToChunks(values, resolution)
  }, [dateRange, timezone, fetchOccupancyData.data?.values])

  function getLineColor() {
    return stateOK ? semanticGood : semanticEmergency
  }

  if (!data) {
    return null
  }

  return (
    <>
      <TitleRow>
        <DateRangePicker
          startDate={utcToHomeTimezone(
            dateRange.startDate.toISOString(),
            timezone
          )}
          endDate={utcToHomeTimezone(dateRange.endDate.toISOString(), timezone)}
          onDateRangeChange={handleDateRangeChange}
          dateRangeProps={{
            minBookingDays: 2,
          }}
          defaultPresetKey="DAY"
        />
      </TitleRow>

      <GraphBox style={graphBoxCSSProps}>
        <LineGraphResponsive
          margin={{ left: 30, right: 30, bottom: 30, top: 30 }}
          data={data}
          unitSymbol=""
          // Use high offset until we can figure out how to not get floats on
          // y-line for smaller numbers:
          yMaxOffset={4}
          yMinOffset={1}
          line={{ stroke: getLineColor() }}
          showLineEndIndicator
          disableTooltip
          lineThresholds={[threshold]}
          timeDomain={timeDomain}
        />
      </GraphBox>
    </>
  )
}

const TitleRow = styled.div`
  margin-top: ${spacing.M};
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.S};
  justify-content: flex-end;
  align-items: center;
`
