import { CSSProperties } from "react"

import { OccupancyStatus } from "src/data/occupancy/types/occupancyTypes"

import { OccupancyGraph } from "./OccupancyGraph"

export function OccupancyContent({
  status,
  threshold,
  aboveThreshold,
  homeId,
  lastUpdate,
  timezone,
  graphCSSProps,
}: {
  status: OccupancyStatus | undefined
  detected: number | undefined
  threshold?: number
  aboveThreshold: boolean
  homeId: string
  lastUpdate: string | undefined
  timezone?: string
  graphCSSProps?: CSSProperties
}) {
  if (!status || status === "waiting_for_data" || !threshold) {
    return null
  }

  return (
    <OccupancyGraph
      id={homeId}
      lastUpdate={lastUpdate}
      threshold={threshold}
      stateOK={!aboveThreshold}
      timezone={timezone}
      graphBoxCSSProps={graphCSSProps}
    />
  )
}
